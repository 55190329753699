import { useState, useEffect } from 'react'
import overlayText from '../../images/chat-overlay.svg'
interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

const ChatOverlay = ({ isOpen, onClose }: ModalProps) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setIsOverlayVisible(true)
      document.addEventListener('click', handleOutsideClick)
    } else {
      setIsOverlayVisible(false)
      document.removeEventListener('click', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])
  const handleOutsideClick = (event: MouseEvent) => {
    event.stopPropagation()
    const modal = document.querySelector('.modal-wrapper.block')
    if (modal?.contains(event.target as Node) && isOpen) {
      onClose()
    }
  }
  return (
    <>
      {isOverlayVisible && (
        <div className="fixed inset-0 z-40 bg-[#120E23B0] opacity-75"></div>
      )}

      <div
        className={`modal-wrapper fixed inset-0 z-50 overflow-y-auto ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <div className="flex min-h-screen items-end justify-end pb-[90px] pr-[25px]">
          <img src={overlayText} alt="chat-with-support" />
        </div>
      </div>
    </>
  )
}

export default ChatOverlay
