import type { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'

import { searchLenders } from '../../api/searchLenders'
import Modal from '../Modal'
import { Tooltip } from 'react-tippy'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

interface DealershipSearchProps {
  selectedLenders: Array<Lender>
  setSelectedLenders: (lenders: Lender[]) => void
  lenderToRemove: Lender
  setLenderToRemove: (lender: Lender | boolean) => void
  showRemoveModal: boolean
  setShowRemoveModal: (open: boolean) => void
  country: string
  session_token: string
  agent_id: string
}

export interface Lender {
  _id: string
  code: string
  name: string
}

const LenderSearch = ({
  selectedLenders,
  setSelectedLenders,
  lenderToRemove,
  setLenderToRemove,
  showRemoveModal,
  setShowRemoveModal,
  country,
  session_token,
  agent_id,
}: DealershipSearchProps) => {
  const [lenderSearchValue, setLenderSearchValue] = useState('')
  const [lenderSearchResults, setLenderSearchResults] = useState<
    Array<Lender> | false
  >(false)

  const dropDownRef = useRef<any>(null)

  const handleLenderSearch = (e: any) => {
    setLenderSearchValue(e.target.value)
  }

  useEffect(() => {
    if (lenderSearchValue !== '') {
      const timer = setTimeout(() => {
        searchLenders(lenderSearchValue, country, session_token, agent_id)
          .then((res) => {
            filterSearchResults(res.data)
          })
          .catch((e: Error | AxiosError) => {
            console.log(e)
          })
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [lenderSearchValue])

  /**
   * Click outside listener
   */
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setLenderSearchResults(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropDownRef])

  const filterSearchResults = (data: Array<Lender>) => {
    const results = data.filter(
      (elem) => !selectedLenders.find(({ _id }) => elem._id === _id)
    )
    setLenderSearchResults(results)
  }

  const handleOptionClick = (option: any) => {
    setLenderSearchResults(false)
    setLenderSearchValue('')
    //push lender to array
    setSelectedLenders([...selectedLenders, option])
  }

  const handleClickRemoveLender = (lender: Lender) => {
    if (showRemoveModal) {
      setLenderToRemove(lender)
    } else {
      handleRemoveLender(lender)
    }
  }

  const handleRemoveLender = (lender: Lender) => {
    var filtered = selectedLenders.filter(function (item) {
      return item._id != lender._id
    })
    setSelectedLenders(filtered)
    setLenderToRemove(false)
  }

  return (
    <div className="mb-6">
      <label
        htmlFor="lenders-label"
        className="mb-[6px] flex text-left text-xs "
      >
        Lenders{' '}
        <Tooltip
          title="Use the search bar below to add lenders to this account. Start by typing the name of a lender and select it from the list of suggested results. For the best experience with our products, be sure to add all of the lenders utilized by this business."
          position="top"
          arrow={true}
        >
          <AiOutlineQuestionCircle className="hover:cursor-pointer" />
        </Tooltip>
      </label>
      <div className="relative">
        <input
          disabled={country == ''}
          id="lenders"
          type="search"
          placeholder="Lender Name"
          value={lenderSearchValue}
          className="w-full rounded bg-transparent px-5 py-2 outline-none transition disabled:cursor-not-allowed disabled:bg-zinc-100"
          onChange={(e) => handleLenderSearch(e)}
        />
        <span className="pointer-events-none absolute">
          <FiSearch className="-ml-10 mt-3 h-5 w-5" />
        </span>
        {lenderSearchResults && lenderSearchValue !== '' && (
          <div
            ref={dropDownRef}
            className={`dropdown-content absolute z-10 my-2 w-full rounded border-2 bg-white p-5 transition duration-300 ease-in-out`}
          >
            {!lenderSearchResults.length ? (
              <div className={`text-p paragraph block p-2`}>
                No results found
              </div>
            ) : (
              lenderSearchResults.map((option) => (
                <div
                  key={option._id}
                  className={`text-p paragraph block cursor-pointer p-2 hover:bg-sidebar-bk`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option?.name}
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={!!lenderToRemove}
        onClose={() => setLenderToRemove(false)}
        children={
          <div>
            <h2 className="mb-16	text-3xl text-paragraph">Remove Lender</h2>
            <p className="text-paragraph">
              Are you sure you want to remove this lender? Once removed, the
              only way to get them back on the list is to add them again
              manually.
            </p>
            <div>
              <input
                className="relative float-left mr-[20px] mt-6 appearance-none rounded-[0.25rem] accent-black"
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
                onChange={() => setShowRemoveModal(!showRemoveModal)}
              />
              <label className="mt-6 inline-block font-normal text-paragraph hover:cursor-pointer">
                Don’t show this again
              </label>
            </div>
            <div className="mt-28">
              <div className="ml-auto flex w-6/12 justify-end">
                <button
                  type="submit"
                  className=" btn btn-secondary mr-[41px] rounded-lg px-8 py-4 text-sm"
                  onClick={() => setLenderToRemove(false)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
                  onClick={() => handleRemoveLender(lenderToRemove)}
                >
                  Remove Lender
                </button>
              </div>
            </div>
          </div>
        }
      />
      {selectedLenders.length ? (
        <div className="mt-2 grid w-full gap-4 xs:grid-cols-1 md:grid-cols-3 ">
          {selectedLenders.map((lender) => {
            return (
              <div
                className="mb-6 mr-[49px] inline-flex items-center  justify-between"
                key={lender?._id}
              >
                <div className="truncate text-ellipsis">
                  <p>{lender?.name}</p>
                </div>
                <div className="inline-flex text-primary">
                  {/* <RiExternalLinkFill /> */}
                  <BiTrash
                    className="cursor-pointer"
                    onClick={() => handleClickRemoveLender(lender)}
                  />
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div
          className={`signin-dialog mb-[20px] mt-[20px] h-32 w-full rounded-lg bg-primary-bk
          shadow-none xs:py-16 xs:pt-8 md:px-32 md:py-32 lg:px-16 lg:pt-8 xl:pt-32`}
        >
          <p className="text-center text-paragraph">
            Add lenders by using the search bar above to select and add lenders
          </p>
        </div>
      )}
    </div>
  )
}

export default LenderSearch
