import { useState } from 'react'

import { newDealerGroup } from '../../api/newDealerGroup'
import Modal from '../Modal'

interface AddNewDealerModalProps {
  isOpen: boolean
  setIsOpenModal: any
  triggerSuccessModal: () => void
  session_token?: string
  agent_id?: string
}

const AddNewDealerGroupModal = ({
  isOpen,
  setIsOpenModal,
  triggerSuccessModal,
  session_token,
  agent_id,
}: AddNewDealerModalProps) => {
  const [formData, setFormData] = useState({
    dealer_group_name: '',
    dealer_group_state: '',
    dealer_group_website: '',
    session_token: '',
    agent_id: '',
  })

  const [formFieldErrors, setFormFieldErrors] = useState({
    dealer_group_name: false,
    dealer_group_state: false,
    dealer_group_website: false,
  })

  const [errorMessage, setErrorMessage] = useState('')

  const handleChangeInput = (e: any) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }))
    setFormFieldErrors((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue == '' ? true : false,
    }))
  }

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const containsSpecialChars = (name: string) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/
    return specialChars.test(name)
  }

  const containsWhitespace = (url: string) => {
    return /\s/.test(url)
  }

  const validateForm = () => {
    const validateFormFields = {
      dealer_group_name: !formData.dealer_group_name.replace(/\s/g, '').length
        ? true
        : containsSpecialChars(formData.dealer_group_name)
        ? true
        : false,
      dealer_group_state: false,
      dealer_group_website:
        containsWhitespace(formData.dealer_group_website) ||
        !isValidUrl(formData.dealer_group_website),
    }
    setFormFieldErrors(validateFormFields)
    const hasError = Object.values(validateFormFields).indexOf(true) > -1
    return hasError
  }

  const handleSubmit = () => {
    //check form validation

    if (validateForm()) {
      return
    }
    if (session_token && agent_id) {
      formData.session_token = session_token
      formData.agent_id = agent_id
    }
    newDealerGroup(formData)
      .then((res) => {
        if (res) {
          setIsOpenModal(false)
          triggerSuccessModal()
        }
      })
      .catch((e) => {
        setErrorMessage(
          e?.response?.data?.data?.message ||
            'There was an error, please verify your information.'
        )
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpenModal(false)}
      children={
        <div className="flex h-[450px] flex-col	">
          <h2 className="mb-16	text-3xl text-paragraph">
            Add New Dealership Group
          </h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="grid w-full gap-4 xs:grid-cols-1 md:grid-cols-2 ">
              <div className="mb-6">
                <label
                  htmlFor="agent-id-number"
                  className="mb-[6px] block text-left text-xs "
                >
                  Dealer Name *
                </label>
                <input
                  id="dealer_group_name"
                  name="dealer_group_name"
                  type="text"
                  placeholder="Dealer Group Name"
                  className={`${
                    formFieldErrors.dealer_group_name
                      ? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error'
                      : 'bg-transparent outline-none'
                  }
                  w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
                  onChange={handleChangeInput}
                  value={formData.dealer_group_name}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="agent-id-number"
                  className="mb-[6px] block text-left text-xs "
                >
                  Website Address *
                </label>
                <input
                  id="dealer_group_website"
                  name="dealer_group_website"
                  type="text"
                  placeholder="https://www.dealershipwebsite.com"
                  className={`${
                    formFieldErrors.dealer_group_website
                      ? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error'
                      : 'bg-transparent outline-none'
                  }
                  w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
                  onChange={handleChangeInput}
                  value={formData.dealer_group_website}
                />
              </div>
            </div>
          </form>
          {errorMessage.length > 0 && (
            <div
              className={`signin-dialog mb-16 h-16 w-full rounded-lg border border-primary bg-primary-bk md:mt-10
        md:px-8 md:py-8 md:pt-8 md:pt-8`}
            >
              <p className="text-center text-paragraph">{errorMessage}</p>
            </div>
          )}
          <div className="mt-auto">
            <div className="ml-auto flex w-6/12 justify-end">
              <button
                className=" btn btn-secondary mr-[41px] rounded-lg px-8 py-4 text-sm"
                onClick={() => setIsOpenModal(false)}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default AddNewDealerGroupModal
