import axios from 'axios'

import { devAPI } from './constants'

export const getPricing = async (session_token: string, agent_id: string) => {
  const res = await axios.get(devAPI + '/get-pricing', {
    params: {
      agent_id,
      session_token,
    },
  })
  return res
}
