import { useEffect, useRef, useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { FiChevronDown } from 'react-icons/fi'
import { Tooltip } from 'react-tippy'

interface RegionSelectProps {
  formFieldErrors: any
  setFormItemValue: (name: string, value: string) => void
  dealership_country: string
}

const RegionSelect = ({
  setFormItemValue,
  formFieldErrors,
  dealership_country,
}: RegionSelectProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const dropDownRef = useRef<any>(null)
  /**
   * Click outside listener
   */
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropDownRef])

  useEffect(() => {
    setSearchValue(
      dealership_country == 'ca'
        ? 'Canada'
        : dealership_country == 'us'
        ? 'United States'
        : ''
    )
  }, [dealership_country])

  const handleOptionClick = (option: any) => {
    setShowOptions(false)
    setSearchValue(option)
    setFormItemValue('dealership_country', option === 'Canada' ? 'ca' : 'us')
  }

  return (
    <div className="mb-[27px]">
      <label
        htmlFor="region-label"
        className="mb-[6px] flex text-left text-xs "
      >
        Region <span className="text-red-700	">*</span>
        <Tooltip
          title="Select the region where the dealership is located from the dropdown menu."
          position="top"
          arrow={true}
        >
          <AiOutlineQuestionCircle className="hover:cursor-pointer" />
        </Tooltip>
      </label>
      <div className="relative">
        <input
          id="dealership_country"
          name="dealership_country"
          type="select"
          placeholder="Choose a Country"
          className={`${
            formFieldErrors.dealership_country
              ? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error'
              : 'bg-transparent outline-none'
          }
              w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
              `}
          value={searchValue}
          onClick={() => setShowOptions(true)}
          readOnly
        />
        <span className="pointer-events-none absolute">
          <FiChevronDown size={24} className="-ml-10 mt-3 h-5 w-5" />
        </span>
        {showOptions && (
          <div
            ref={dropDownRef}
            className={`dropdown-content absolute z-10 my-2 w-full rounded border-2 bg-white p-5 transition duration-300 ease-in-out`}
          >
            <div
              className={`block cursor-pointer p-2 text-paragraph hover:bg-sidebar-bk`}
              onClick={() => handleOptionClick('United States')}
            >
              United States
            </div>
            <div
              className={`block cursor-pointer p-2 text-paragraph hover:bg-sidebar-bk`}
              onClick={() => handleOptionClick('Canada')}
            >
              Canada
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RegionSelect
