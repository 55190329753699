//import axios from 'axios'
import type { AxiosError } from "axios";
import { useEffect, useState, useRef } from "react";
import { AiFillPlusCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";

import { agentLogin } from "../../api/agentLogin";
import { newDealer } from "../../api/newDealer";
import { getPricing } from "../../api/pricing";
import ActivateAccountModal from "../../components/ActivateAccountModal";
import ChatModalOverlay from "../../components/ChatModalOverlay";
import ChatOverlay from "../../components/ChatOverlay";
import DealerGroupSearch from "../../components/DealerGroupSearch";
import DealershipSearch from "../../components/DealerSearch";
import ErrorModal from "../../components/ErrorModal";
import type { Lender } from "../../components/LenderSearch";
import LenderSearch from "../../components/LenderSearch";
import LoginErrorScreen from "../../components/LoginErrorScreen";
import LoginForm from "../../components/LoginForm";
import PlanSelect from "../../components/PlanSelect";
import RegionSelect from "../../components/RegionSelect";
import WebsiteNotFoundModal from "../../components/WebsiteNotFoundModal";
import OnboardingLayout from "../../layout/OnboardingLayout";

const _DEFAULT_CA_LENDERS = [
	{
		_id: "64bd50137a37faecdbba1479",
		code: "CA-168",
		name: "iA Auto Finance"
	},
	{
		_id: "64bd50167a37faecdbba148b",
		code: "CA-297",
		name: "Royal Bank"
	},
	{
		_id: "64bd50177a37faecdbba148d",
		code: "CA-246",
		name: "Santander Consumer"
	},
	{
		_id: "64bd50177a37faecdbba148f",
		code: "CA-223",
		name: "Scotia Bank"
	},
	{
		_id: "64bd50177a37faecdbba1491",
		code: "CA-210",
		name: "Scotia Dealer Advantage"
	},
	{
		_id: "64bd50197a37faecdbba1497",
		code: "CA-282",
		name: "TD Auto Finance"
	}
];

const _DEFAULT_US_LENDERS = [
	{
		_id: "64bd5028cea17d483b2ef717",
		code: "US-11",
		name: "Ally Financial"
	},
	{
		_id: "64bd502bcea17d483b2ef725",
		code: "US-159",
		name: "Bank of America"
	},
	{
		_id: "64bd5030cea17d483b2ef73b",
		code: "US-160",
		name: "Capital One Auto Finance"
	},
	{
		_id: "64bd5031cea17d483b2ef73f",
		code: "US-166",
		name: "Chase Auto Finance"
	},
	{
		_id: "64bd5035cea17d483b2ef753",
		code: "US-185",
		name: "Exeter Finance"
	},
	{
		_id: "64bd504ecea17d483b2ef7d5",
		code: "US-163",
		name: "TD Auto Finance"
	},
	{
		_id: "64bd5054cea17d483b2ef7f1",
		code: "US-101",
		name: "Wells Fargo"
	},
	{
		_id: "64bd5054cea17d483b2ef7f3",
		code: "US-153",
		name: "Westlake Financial"
	}
];

const OnboardingForm = () => {
	const [formData, setFormData] = useState({
		agent_id: "",
		contact_email: "",
		contact_full_name: "",
		contact_phone: "",
		dealer_group_domain: "",
		dealership_additional_websites: "",
		dealership_billing_email: "",
		dealership_country: "",
		dealership_domain: "",
		dealership_lead_email: "",
		dealership_lead_email_alt: "",
		dealership_name: "",
		dealership_phone: "",
		dealership_providers: [""],
		dealership_website: "",
		include_marketplace: false,
		lead_option: "",
		plan: "",
		price: ""
	});

	const [formFieldErrors, setFormFieldErrors] = useState({
		//agent_id: false,
		contact_email: false,
		contact_full_name: false,
		contact_phone: false,
		//dealer_group_domain: false,
		dealership_billing_email: false,

		dealership_country: false,
		dealership_lead_email: false,
		dealership_lead_email_alt: false,
		//dealership_name: false,
		//dealership_phone: false,
		//dealership_providers: false,
		dealership_website: false,
		plan: false,
		price: false
	});

	const [verifyInfo, setVerifyInfo] = useState(false);
	const [showWebsiteNotFound, setShowWebsiteNotFound] = useState(false);
	const [showActivateAccountModal, setShowActivateAccountModal] =
		useState(false);
	const [selectedEmailType, setSelectedEmailType] = useState("inbox");
	const [selectedAltEmailType, setSelectedAltEmailType] = useState("inbox");
	const [showAltEmail, setShowAltEmail] = useState(false);

	const [selectedLenders, setSelectedLenders] = useState<Array<Lender>>([]);
	const [lenderToRemove, setLenderToRemove] = useState<Lender | any>(false);
	const [showRemoveLenderModal, setShowRemoveLenderModal] = useState(true);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("false");
	const [additionalDomains, setAdditionalDomains] = useState(Array<string>);
	const [isLoading, setIsLoading] = useState(false);
	const [isChatOverlayOpen, setIsChatOverlayOpen] = useState(false);
	const [isChatModalOverlayOpen, setIsChatModalOverlayOpen] = useState(false);
	const [includeMarketplace, setIncludeMarketplace] = useState(true);
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [agentLoginData, setAgentLoginData] = useState({
		agent_id: "",
		session_token: ""
	});
	const [isLoginError, setIsLoginError] = useState(false);
	const [pricingOptions, setPricingOptions] = useState([]);

	const triggerChatOverlay = () => {
		setShowWebsiteNotFound(false);
		setIsChatOverlayOpen(true);
	};
	const closeChatOverlay = () => {
		setIsChatOverlayOpen(false);
	};
	const triggerChatModalOverlay = () => {
		setShowErrorModal(false);
		setIsChatModalOverlayOpen(true);
	};
	const closeChatModalOverlay = () => {
		setIsChatModalOverlayOpen(false);
	};
	//Read location URL parameter
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const location = urlParams.get("country");
	const plan = urlParams.get("plan");
	const loginData = urlParams.get("loginData");

	const overrideCheckboxRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (loginData) {
			loginAgent();
		}
	}, []);

	const loginAgent = () => {
		setIsLoggingIn(true);
		agentLogin(loginData)
			.then((res) => {
				setAgentLoginData(res.data.data);
				setIsLoggingIn(false);
				setFormData((prevState) => ({
					...prevState,
					["agent_id"]: res.data.data.agent_id
				}));
			})
			.catch((e: Error | AxiosError) => {
				setIsLoggingIn(false);
				setIsLoginError(true);
			});
	};

	useEffect(() => {
		if (!agentLoginData.session_token) {
			return;
		}
		getPricing(agentLoginData.session_token, agentLoginData.agent_id)
			.then((res) => {
				setPricingOptions(res.data.data.plans);
			})
			.catch((e: Error | AxiosError) => {
				console.log(e);
			});
	}, [agentLoginData]);
	useEffect(() => {
		if (!location) {
			//getGeoInfo()
			return;
		}
		setFormData((prevState) => ({
			...prevState,
			["dealership_country"]: location
		}));
	}, []);

	useEffect(() => {
		if (!plan) {
			//getGeoInfo()
			return;
		}
		setFormData((prevState) => ({
			...prevState,
			["plan"]: plan
		}));
	}, []);

	useEffect(() => {
		if (formData.dealership_country === "ca") {
			setSelectedLenders(_DEFAULT_CA_LENDERS);
		} else if (formData.dealership_country === "us") {
			setSelectedLenders(_DEFAULT_US_LENDERS);
		} else {
			setSelectedLenders([]);
		}
	}, [formData.dealership_country]);
	/**
	 * [validateForm] validates all fields
	 */
	const validateForm = () => {
		//Check email
		const emailDomain = formData.contact_email.split("@").pop();
		const validateFormFields = {
			contact_email:
				!validateEmail(formData.contact_email) ||
				(!overrideCheckboxRef?.current?.checked &&
					!formData.dealership_domain.includes(
						emailDomain as string
					)),
			contact_full_name: formData.contact_full_name === "" ? true : false,
			contact_phone: formData.contact_phone.length < 12 ? true : false,
			dealership_billing_email: !validateEmail(
				formData.dealership_billing_email
			),
			dealership_country:
				formData.dealership_country === "ca" ||
				formData.dealership_country === "us"
					? false
					: true,
			dealership_lead_email: !validateEmail(
				formData.dealership_lead_email
			),
			dealership_lead_email_alt:
				formData.dealership_lead_email_alt === ""
					? false
					: !validateEmail(formData.dealership_lead_email_alt) ||
					  formData.dealership_lead_email_alt ===
							formData.dealership_lead_email,
			dealership_website:
				formData.dealership_website === "" ? true : false,
			plan: formData.plan === "" ? true : false,
			price: formData.price === "" ? true : false
		};
		setFormFieldErrors(validateFormFields);
		if (Object.values(validateFormFields).includes(true)) {
			if (validateFormFields.contact_email) {
				setShowWebsiteNotFound(true);
			}
			return;
		}
		createNewDealer();
	};
	const createNewDealer = () => {
		setIsLoading(true);
		const data: any = { ...formData };
		data.dealership_country = data.dealership_country.toUpperCase();

		data.override_domain_match = overrideCheckboxRef?.current?.checked;

		data.dealership_website = data.dealership_website;
		data.dealership_additional_websites = additionalDomains.toString();
		data.dealership_providers = selectedLenders.map((lender) => lender._id);
		if (!data.dealership_additional_websites.length) {
			delete data.dealership_additional_websites;
		}
		delete data.dealership_domain;
		data.include_marketplace = includeMarketplace;
		if (agentLoginData.session_token) {
			data.session_token = agentLoginData.session_token;
		}

		data.lead_delivery_emails = [
			{
				email: data.dealership_lead_email,
				method: selectedEmailType
			}
		];
		if (data.dealership_lead_email_alt.length) {
			data.lead_delivery_emails.push({
				email: data.dealership_lead_email_alt,
				method: selectedAltEmailType
			});
		}
		delete data.dealership_lead_email_alt;
		newDealer(data)
			.then((res) => {
				setIsLoading(false);
				if (res) {
					setShowActivateAccountModal(true);
				}
			})
			.catch((e) => {
				setIsLoading(false);
				setShowErrorModal(true);
				setErrorMessage(
					e?.response?.data?.data?.message ||
						"There was an error, please try again later or contact support."
				);
			});
	};

	const handleChangeInput = (e: any) => {
		const fieldName = e.target.name;
		let fieldValue = e.target.value;
		if (fieldName === "contact_phone") {
			fieldValue = phoneFormat(e.target.value);
		}

		setFormData((prevState) => ({
			...prevState,
			[fieldName]: fieldValue
		}));
		setFormFieldErrors((prevState) => ({
			...prevState,
			[fieldName]: fieldValue === "" ? true : false
		}));
	};
	const phoneFormat = (input: any) => {
		if (!input) return input;
		const phoneNumber = input.replace(/[^\d]/g, "");
		const phoneNumberLength = phoneNumber.length;
		if (phoneNumberLength < 4) return phoneNumber;
		if (phoneNumberLength < 7) {
			return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
		}
		return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
			3,
			6
		)}-${phoneNumber.slice(6)}`;
	};

	const setFormItemValue = (name: string, value: string) => {
		setFormData((prevState) => ({
			...prevState,
			[name]: value
		}));
		if (name === "price" && value === "") {
			return;
		}
		setFormFieldErrors((prevState) => ({
			...prevState,
			[name]: value === "" ? true : false
		}));
	};

	const validateEmail = (email: string) => {
		const validate = String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		return validate;
	};

	const resetValidation = () => {
		setFormFieldErrors({
			//agent_id: false,
			contact_email: false,
			contact_full_name: false,
			contact_phone: false,
			//dealer_group_domain: false,
			dealership_billing_email: false,
			dealership_country: false,
			dealership_lead_email: false,
			dealership_lead_email_alt: false,
			//dealership_name: false,
			//dealership_phone: false,
			//dealership_providers: false,
			dealership_website: false,
			plan: false,
			price: false
		});
	};

	return (
		<OnboardingLayout>
			{!loginData ? (
				<LoginForm />
			) : isLoggingIn ? (
				<div className="items-center">
					<svg
						aria-hidden="true"
						className="text-gray-200 dark:text-gray-600 ml-[5px] mr-2 mt-[5px] inline h-10 w-10 animate-spin fill-blue-600"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="currentColor"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentFill"
						/>
					</svg>
				</div>
			) : isLoginError ? (
				<LoginErrorScreen openSupportTab={triggerChatOverlay} />
			) : (
				<div className="grid h-full w-full xs:w-11/12 xs:grid-cols-3 xs:gap-4">
					<div className="xs:col-span-3 xl:col-span-2">
						<h1 className="text-5xl">
							UltraLead Account Registration
						</h1>
						<div className="mb-[27px] mt-[27px] text-justify">
							Please use the form below to register a new account.
							If you're new to our system or need assistance,
							check out{" "}
							<a
								href="https://scribehow.com/shared/Onboarding_a_New_Client_with_Carbeeza__uuNYvXRRQiukjHk_WjuiVA?referrer=workspace"
								className="text-primary hover:underline"
							>
								our tutorial
							</a>{" "}
							for guidance on the registration process.
							<br />
							<br />
							If you have any questions, you can reach our support
							team at{" "}
							<a
								href="mailto:support@carbeeza.com"
								className="text-primary hover:underline"
							>
								support@carbeeza.com
							</a>{" "}
							or ask our help bot in the bottom right corner.
						</div>
						<RegionSelect
							formFieldErrors={formFieldErrors}
							setFormItemValue={setFormItemValue}
							dealership_country={formData.dealership_country}
						/>
						<PlanSelect
							formFieldErrors={formFieldErrors}
							setFormItemValue={setFormItemValue}
							plan={formData.plan}
							pricingOptions={pricingOptions}
							country={formData.dealership_country}
						/>
						<div className="mb-[27px] mr-4 mt-[0px] inline-block min-h-[1.5rem] pl-[1.5rem]">
							<input
								className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] hover:cursor-pointer focus:ring-transparent"
								type="checkbox"
								id="include_marketplace"
								name="include_marketplace"
								value="include_marketplace"
								defaultChecked
								onClick={() =>
									setIncludeMarketplace(!includeMarketplace)
								}
							/>
							<label className="inline-block text-base font-normal ">
								Include Marketplace Subscription
							</label>
						</div>
						<div className="mb-[27px]">
							<label
								htmlFor="agent-id-number"
								className="mb-[6px] flex text-left text-xs "
							>
								Agent ID Number{" "}
								<Tooltip
									title="If you are an agent, please enter your unique Agent ID number to claim this dealership as your client. Please check your entry is correct, any errors may result in lost or delayed payment. If you have misplaced your ID number, please contact support."
									position="top"
									arrow={true}
								>
									<AiOutlineQuestionCircle className="hover:cursor-pointer" />
								</Tooltip>
							</label>
							<input
								id="agent_id"
								name="agent_id"
								type="number"
								placeholder="123456"
								className={
									"w-full rounded  bg-transparent px-5 py-2 outline-none transition [appearance:textfield] disabled:cursor-default [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
								}
								value={formData.agent_id}
								onChange={handleChangeInput}
								onKeyDown={(evt) =>
									["e", "E", "+", "-", "."].includes(
										evt.key
									) && evt.preventDefault()
								}
							/>
						</div>

						{formData.dealership_country === "" ||
						formData.dealership_country === "us" || //This enables the form for US, leaving the condition like this in case we need to undo
						formData.dealership_country === "ca" ? (
							<div>
								<DealershipSearch
									setFormItemValue={setFormItemValue}
									formFieldErrors={formFieldErrors}
									openSupportTab={triggerChatOverlay}
									openModalOverlay={triggerChatOverlay}
									additionalDomains={additionalDomains}
									setAdditionalDomains={setAdditionalDomains}
									country={formData.dealership_country}
									resetValidation={resetValidation}
									session_token={agentLoginData.session_token}
									agent_id={agentLoginData.agent_id}
								/>
								<DealerGroupSearch
									setFormItemValue={setFormItemValue}
									formFieldErrors={formFieldErrors}
									agent_id={agentLoginData.agent_id}
									session_token={agentLoginData.session_token}
								/>
								<LenderSearch
									selectedLenders={selectedLenders}
									setSelectedLenders={setSelectedLenders}
									lenderToRemove={lenderToRemove}
									setLenderToRemove={setLenderToRemove}
									showRemoveModal={showRemoveLenderModal}
									setShowRemoveModal={
										setShowRemoveLenderModal
									}
									country={formData.dealership_country}
									session_token={agentLoginData.session_token}
									agent_id={agentLoginData.agent_id}
								/>
								<hr className="mb-[50px] mt-[0px] h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
								<div className="mb-[30px]">
									<label
										htmlFor="agent-id-number"
										className="mb-[6px] flex text-left text-xs "
									>
										Lead Delivery Address{" "}
										<span className="text-red-700	">*</span>
										<Tooltip
											title="Enter the email address where the dealer receives incoming leads. Please indicate if this email address is for the CRM, or an inbox."
											position="top"
											arrow={true}
										>
											<AiOutlineQuestionCircle className="hover:cursor-pointer" />
										</Tooltip>
									</label>
									<input
										id="dealership_lead_email"
										name="dealership_lead_email"
										type="email"
										placeholder="leads@example.com"
										className={`${
											formFieldErrors.dealership_lead_email
												? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
												: "bg-transparent outline-none"
										}
                  w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
										value={formData.dealership_lead_email}
										onChange={handleChangeInput}
									/>
									<div className="mt-3.5 text-base xl:inline-flex">
										{!showAltEmail ? (
											<div
												className="mr-[54px] mt-3.5 flex cursor-pointer flex-row items-center text-base text-primary"
												onClick={() =>
													setShowAltEmail(true)
												}
											>
												<AiFillPlusCircle />
												<span className="ml-1 border-b">
													Add Additional email address
												</span>
											</div>
										) : (
											""
										)}
										<div className="flex xl:justify-center">
											<div className="mb-[0.125rem] mr-4 mt-[15px] inline-block min-h-[1.5rem] pl-[1.5rem]">
												<input
													className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] hover:cursor-pointer focus:ring-transparent"
													type="radio"
													id="inbox"
													name="inbox"
													value="inbox"
													checked={
														selectedEmailType ===
														"inbox"
													}
													onChange={() =>
														setSelectedEmailType(
															"inbox"
														)
													}
												/>
												<label className="inline-block text-base font-normal ">
													Inbox
												</label>
											</div>

											<div className="mb-[0.125rem] mr-4 mt-[15px] inline-block min-h-[1.5rem] pl-[1.5rem]">
												<input
													className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] hover:cursor-pointer focus:ring-transparent"
													type="radio"
													id="crm"
													name="crm"
													value="crm"
													checked={
														selectedEmailType ===
														"crm"
													}
													onChange={() =>
														setSelectedEmailType(
															"crm"
														)
													}
												/>
												<label className="inline-block text-base font-normal ">
													CRM
												</label>
											</div>
										</div>
									</div>
								</div>
								{showAltEmail && (
									<div className="mb-6">
										<label
											htmlFor="agent-id-number"
											className="mb-[6px] block text-left text-xs "
										>
											Alternative Delivery Address
											<span className="text-red-700	">
												*
											</span>
										</label>
										<input
											id="dealership_lead_email_alt"
											name="dealership_lead_email_alt"
											type="text"
											placeholder="email@email.com"
											className={`${
												formFieldErrors.dealership_lead_email_alt
													? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
													: "bg-transparent outline-none"
											}
                  w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
											value={
												formData.dealership_lead_email_alt
											}
											onChange={handleChangeInput}
										/>
										<div className="mt-3.5 text-base xl:inline-flex">
											<div className="flex xl:justify-center">
												<div className="mb-[0.125rem] mr-4 mt-[15px] inline-block min-h-[1.5rem] pl-[1.5rem]">
													<input
														className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] hover:cursor-pointer focus:ring-transparent"
														type="radio"
														id="inbox_alt"
														name="inbox_alt"
														value="inbox_alt"
														checked={
															selectedAltEmailType ===
															"inbox"
														}
														onChange={() =>
															setSelectedAltEmailType(
																"inbox"
															)
														}
													/>
													<label className="inline-block text-base font-normal ">
														Inbox
													</label>
												</div>

												<div className="mb-[0.125rem] mr-4 mt-[15px] inline-block min-h-[1.5rem] pl-[1.5rem]">
													<input
														className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] hover:cursor-pointer focus:ring-transparent"
														type="radio"
														id="crm_alt"
														name="crm_alt"
														value="crm_alt"
														checked={
															selectedAltEmailType ===
															"crm"
														}
														onChange={() =>
															setSelectedAltEmailType(
																"crm"
															)
														}
													/>
													<label className="inline-block text-base font-normal ">
														CRM
													</label>
												</div>
											</div>
										</div>
									</div>
								)}
								<div className="grid w-full gap-4 xs:grid-cols-1 xl:grid-cols-2 ">
									<div className="mb-[40px]">
										<label
											htmlFor="agent-id-number"
											className="mb-[6px] flex text-left text-xs "
										>
											Billing Email Address{" "}
											<span className="text-red-700	">
												*
											</span>
											<Tooltip
												title="Enter the email address that Carbeeza should send invoices, receipts and other billing inquiries to. The remainder of the billing information will be collected once the account has been verified."
												position="top"
												arrow={true}
											>
												<AiOutlineQuestionCircle className="hover:cursor-pointer" />
											</Tooltip>
										</label>
										<input
											id="dealership_billing_email"
											name="dealership_billing_email"
											type="text"
											placeholder="billing@example.com"
											className={`${
												formFieldErrors.dealership_billing_email
													? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
													: "bg-transparent outline-none"
											}
                  w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
											value={
												formData.dealership_billing_email
											}
											onChange={handleChangeInput}
										/>
									</div>
									<div className="mb-[40px]">
										<label
											htmlFor="agent-id-number"
											className="mb-[6px] flex text-left text-xs "
										>
											Authorized Contact Name{" "}
											<span className="text-red-700">
												*
											</span>
											<Tooltip
												title="Please supply the contact information for the person who will be in charge of making decisions regarding this account. This person will be authorized to enter into agreements on behalf of the business indicated above."
												position="top"
												arrow={true}
											>
												<AiOutlineQuestionCircle className="hover:cursor-pointer" />
											</Tooltip>
										</label>
										<input
											id="contact_full_name"
											name="contact_full_name"
											type="text"
											placeholder="FirstName LastName"
											className={`${
												formFieldErrors.contact_full_name
													? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
													: "bg-transparent outline-none"
											}
                  w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
											value={formData.contact_full_name}
											onChange={handleChangeInput}
										/>
									</div>
									<div className="mb-[16px]">
										<label
											htmlFor="agent-id-number"
											className="mb-[6px] flex text-left text-xs "
										>
											Authorized Contact Email Address{" "}
											<span className="text-red-700	">
												*
											</span>
											<Tooltip
												title="The authorized contact email address must match one of the dealership web domains provided above. If the domains do not match, you can request a manual domain verification and override. This process can take up to 5 business days during peak times."
												position="top"
												arrow={true}
											>
												<AiOutlineQuestionCircle className="hover:cursor-pointer" />
											</Tooltip>
										</label>
										<input
											id="contact_email"
											name="contact_email"
											type="text"
											placeholder="contact@example.com"
											className={`${
												formFieldErrors.contact_email
													? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
													: "bg-transparent outline-none"
											}
                  w-full rounded  px-5 py-2 outline-none transition after:content-['*'] disabled:cursor-default
                  `}
											value={formData.contact_email}
											onChange={handleChangeInput}
										/>
										<div className="my-[0.25rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
											<input
												ref={overrideCheckboxRef}
												className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] accent-black focus:ring-transparent"
												type="checkbox"
												id="overrideCheckbox"
												value="true"
											/>
											<label className="inline-block text-base font-normal hover:cursor-pointer">
												<div className="text-gray-100 mt-1 text-xs">
													Request domain match
													override.
												</div>
											</label>
										</div>
									</div>
									<div className="mb-[16px]">
										<label
											htmlFor="agent-id-number"
											className="mb-[6px] block text-left text-xs "
										>
											Authorized Contact Phone Number{" "}
											<span className="text-red-700	">
												*
											</span>
										</label>
										<input
											maxLength={12}
											id="contact_phone"
											name="contact_phone"
											type="text"
											placeholder="123-123-4567 "
											className={`${
												formFieldErrors.contact_phone
													? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
													: "bg-transparent outline-none"
											}
                  w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
											value={formData.contact_phone}
											onChange={handleChangeInput}
										/>
									</div>
								</div>
								<hr />
								<br />
								<div className="grid w-full grid-cols-2 gap-4 xs:grid-cols-1 ">
									<div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
										<input
											className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] accent-black focus:ring-transparent"
											type="checkbox"
											id="inlineCheckbox1"
											value="option1"
											onChange={() =>
												setVerifyInfo(!verifyInfo)
											}
										/>
										<label className="inline-block text-base font-normal hover:cursor-pointer">
											<div className="text-gray-100 mt-1 text-xs">
												I hereby confirm the following:
												<ul className="ml-6 list-decimal">
													<li>
														I, the party performing
														this registration, am
														authorized to act on
														behalf of the business
														indicated in the form
														above to register this
														account and that all the
														information provided is
														correct and complete.
													</li>
													<li>
														I confirm that the
														contact information
														listed in the fields
														titled "Authorized
														Contact Name",
														"Authorized Contact
														Phone Number", and
														"Authorized Email
														Address" in the form
														above belongs to an
														individual who is duly
														authorized to enter into
														agreements on behalf of
														the business indicated
														above.
													</li>
													<li>
														I confirm that the
														authorized contact
														listed above has read
														and agreed to the terms
														outlined in the{" "}
														<a
															href="http://www.ultralead.ai/s/TOS-UltraLead.pdf"
															target="_blank"
															className="text-primary"
														>
															Terms of Service
														</a>{" "}
														which constitutes a
														binding user agreement
														between the business
														indicated and Carbeeza
														Inc.
													</li>
												</ul>
											</div>
										</label>
									</div>
									<div />
									<div />
									<div>
										<button
											type="submit"
											className="btn-primary flex w-full justify-center rounded-lg py-3"
											disabled={!verifyInfo || isLoading}
											onClick={validateForm}
										>
											Continue
											{isLoading && (
												<svg
													aria-hidden="true"
													className="text-gray-200 dark:text-gray-600 ml-[5px] mr-2 mt-[5px] inline h-6 w-6 animate-spin fill-blue-600"
													viewBox="0 0 100 101"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
														fill="currentColor"
													/>
													<path
														d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
														fill="currentFill"
													/>
												</svg>
											)}
										</button>
									</div>
								</div>
							</div>
						) : (
							<div
								className={`signin-dialog mb-16 mt-10 h-32 w-full rounded-lg border border-primary bg-primary-bk
            xs:px-8 xs:py-8 xs:pt-8 xl:px-32 xl:py-32 xl:pt-32`}
							>
								<p className="text-center text-paragraph">
									UltraLead (USA) onboarding is undergoing
									maintenance. For urgent inquiries, please
									contact us at{" "}
									<a
										href="mailto:support@carbeeza.com"
										className="text-primary hover:underline"
									>
										support@carbeeza.com.
									</a>
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			{showWebsiteNotFound && (
				<WebsiteNotFoundModal
					setModalOpen={setShowWebsiteNotFound}
					openSupportTab={triggerChatOverlay}
				/>
			)}
			{showActivateAccountModal && (
				<ActivateAccountModal
					setModalOpen={setShowActivateAccountModal}
					contactEmail={formData.contact_email}
					overrideDomainMatch={overrideCheckboxRef?.current?.checked}
				/>
			)}
			{showErrorModal && (
				<ErrorModal
					setModalOpen={setShowErrorModal}
					openSupportTab={triggerChatModalOverlay}
					message={errorMessage}
				/>
			)}
			{isChatOverlayOpen && (
				<ChatOverlay
					isOpen={isChatOverlayOpen}
					onClose={closeChatOverlay}
				/>
			)}
			{isChatModalOverlayOpen && (
				<ChatModalOverlay
					isOpen={isChatModalOverlayOpen}
					onClose={closeChatModalOverlay}
				/>
			)}
		</OnboardingLayout>
	);
};

export default OnboardingForm;
