import Modal from "../Modal";

interface DealershipRegisteredModalProps {
	setModalOpen: (open: boolean) => void;
	contactEmail: string;
	overrideDomainMatch: boolean | undefined;
}
const DealershipRegisteredModal = ({
	setModalOpen,
	contactEmail,
	overrideDomainMatch
}: DealershipRegisteredModalProps) => {
	return (
		<Modal
			isOpen={true}
			onClose={() => setModalOpen(false)}
			children={
				<div className="flex h-[570px] flex-col">
					<div className={`${overrideDomainMatch ? "hidden" : ""}`}>
						<h2 className="mb-6	text-[32px] font-medium text-[#000000]	">
							Activate Account
						</h2>
						<h3 className="mb-8	text-[20px] font-medium text-[#000000]">
							1. Email Verification
						</h3>
						<p className="text-[20px] font-normal text-[#000000]">
							We have sent an account verification email to
							<span className="text-primary">
								{" "}
								{contactEmail}
							</span>
							. Please follow the link in the email to verify the
							account. If the email does not come through, please
							contact support.
						</p>
						<h3 className="mb-6 mt-6 text-[20px] font-medium text-[#000000]">
							2. Add Payment Method
						</h3>
						<p className="text-[20px] font-normal text-[#000000]">
							To activate the account, a valid payment method is
							required. A link to add a payment method will be
							sent via email once the address is verified.
							<br />
							<br />
							The account holder will not be charged for services
							until the end of the free trial period.
						</p>
					</div>
					<div className={`${overrideDomainMatch ? "" : "hidden"}`}>
						<h2 className="mb-6	text-[32px] font-medium text-[#000000]	">
							Domain Match Override Requested
						</h2>
						<p className="text-[20px] font-normal text-[#000000]">
							Our team has been notified of your request and a
							ticket has been created to perform a manual domain
							verification. During peak times, this can take up to
							5 business days.
						</p>
						<p className="text-[20px] font-normal text-[#000000]">
							Once this process is complete, we will send an email
							to the authorized contact to verify their new
							account and email address. Following verification,
							the authorized contact will be asked to provide a
							valid payment method and approve the purchase(s) to
							complete the account activation process.
						</p>
					</div>
					<div className="mt-auto">
						<div className="ml-auto flex justify-end">
							<button
								type="submit"
								className=" btn btn-secondary mr-[41px] rounded-lg px-8 py-4 text-sm"
								onClick={() => window.location.reload()}
							>
								Add Another Dealer
							</button>
							<button
								type="submit"
								className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
								onClick={() => setModalOpen(false)}
							>
								Done
							</button>
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default DealershipRegisteredModal;
