import type { ReactNode } from 'react'

import Footer from './Footer'
import Header from './Header'

interface OnboardingLayoutProps {
  children: ReactNode
}

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return (
    <div id="wrapper">
      <Header />
      <div className="main-content relative flex bg-sidebar-bk">
        <aside
          className={`'translate-x-0' invisible absolute left-0 top-0 z-30 flex h-full w-[360px] flex-col bg-sidebar-bk duration-300 ease-linear xl:visible  xl:static xl:z-10 xl:translate-x-0`}
        >
          <div className="no-scrollbar sticky top-20 flex flex-col overflow-y-auto duration-300 ease-linear"></div>
        </aside>
        <main className="flex flex-1 bg-white pb-24 pl-8 pr-4 pt-14 md:pl-10 md:pr-6 2xl:pl-20 2xl:pr-11">
          {children}
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default OnboardingLayout
