import type { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { AiFillPlusCircle, AiOutlineQuestionCircle } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi'

import { searchDealerGroups } from '../../api/searchDealerGroups'
import AddNewDealerGroupModal from '../AddNewDealerGroupModal'
import { Tooltip } from 'react-tippy'
import Modal from '../Modal'

interface DealershipSearchProps {
  formFieldErrors: any
  setFormItemValue: (name: string, value: string) => void
  agent_id: string
  session_token: string
}

interface DealerGroup {
  _id: string
  dealer_group_name: string
  dealer_group_website: string
}

const DealerGroupSearch = ({
  setFormItemValue,
  formFieldErrors,
  agent_id,
  session_token,
}: DealershipSearchProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [dealerGroupSearchResults, setDealerGroupSearchResults] = useState<
    Array<DealerGroup> | false
  >(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)
  const dropDownRef = useRef<any>(null)

  /**
   * Click outside listener
   */
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDealerGroupSearchResults(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropDownRef])

  const handleDealerGroupSearch = (e: any) => {
    setSearchValue(e.target.value)
    //debounce
    let delayDebounceFn
    clearTimeout(delayDebounceFn)
    if (e.target.value !== '') {
      delayDebounceFn = setTimeout(() => {
        searchDealerGroups(e.target.value, session_token, agent_id)
          .then((res) => setDealerGroupSearchResults(res.data))
          .catch((e: Error | AxiosError) => console.log(e))
      }, 500)
    }
  }

  const handleOptionClick = (option: any) => {
    setDealerGroupSearchResults(false)
    setSearchValue(option?.dealer_group_name)
    setFormItemValue('dealer_group_domain', option?.dealer_group_website)
  }

  const triggerSuccessModal = () => {
    setIsOpenSuccessModal(true)
  }

  return (
    <div className="mb-[27px]">
      <label
        htmlFor="dealer-group-label"
        className="mb-[6px] flex text-left text-xs "
      >
        Dealer Group (optional){' '}
        <Tooltip
          title="If this dealership belongs to a dealer group, use the search bar below to find the group. If the group name does not appear in the search results, click “Add New”."
          position="top"
          arrow={true}
        >
          <AiOutlineQuestionCircle className="hover:cursor-pointer" />
        </Tooltip>
      </label>
      <div className="relative">
        <input
          id="dealership_name"
          type="search"
          placeholder="Dealer Group Name"
          className={`${
            formFieldErrors.dealership_name
              ? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error'
              : 'bg-transparent outline-none'
          }
              w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
              `}
          value={searchValue}
          onChange={(e) => handleDealerGroupSearch(e)}
        />
        <span className="pointer-events-none absolute">
          <FiSearch className="-ml-10 mt-3 h-5 w-5" />
        </span>
        {dealerGroupSearchResults && searchValue !== '' && (
          <div
            ref={dropDownRef}
            className={`dropdown-content absolute z-10 my-2 w-full rounded border-2 bg-white p-5 transition duration-300 ease-in-out`}
          >
            {!dealerGroupSearchResults.length ? (
              <div className={`block p-2 text-paragraph`}>No results found</div>
            ) : (
              dealerGroupSearchResults.map((option) => (
                <div
                  key={option._id}
                  className={`block cursor-pointer p-2 text-paragraph hover:bg-sidebar-bk`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option?.dealer_group_name}
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <div
        className="mt-3.5 flex cursor-pointer flex-row items-center text-base text-primary"
        onClick={() => {
          setIsOpenModal(true)
        }}
      >
        <AiFillPlusCircle />
        <span className="ml-1 border-b ">Add new</span>
      </div>
      {isOpenModal && (
        <AddNewDealerGroupModal
          isOpen={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          triggerSuccessModal={triggerSuccessModal}
          agent_id={agent_id}
          session_token={session_token}
        />
      )}
      {isOpenSuccessModal && (
        <Modal
          isOpen={isOpenSuccessModal}
          onClose={() => setIsOpenSuccessModal(false)}
          children={
            <div className="flex h-[350px] flex-col	">
              <h2 className="mb-[40px] text-[32px]	font-medium text-[paragraph]">
                Success!
              </h2>
              <p className="text-[#3B2F66} text-[20px]">
                New dealer group has been created successfully.
              </p>
              <div className="mt-auto">
                <div className="ml-auto flex w-6/12 justify-end">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
                    onClick={() => setIsOpenSuccessModal(false)}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          }
        ></Modal>
      )}
    </div>
  )
}

export default DealerGroupSearch
