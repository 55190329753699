import axios from 'axios'

import { devAPI } from './constants'

export const newDealer = async (params: any) => {
  const res = await axios.post(devAPI + '/new-dealer', {
    ...params,
  })
  return res
}
