import axios from 'axios'

import { devAPI } from './constants'

export const searchDealerGroups = async (
  query: string,
  session_token: string,
  agent_id: string
) => {
  const res = await axios.get(devAPI + '/search-dealer-groups', {
    params: {
      query,
      session_token,
      agent_id,
    },
  })
  return res
}
