import type { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import {
  AiFillPlusCircle,
  AiOutlineQuestionCircle,
  AiOutlineWarning,
} from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi'
import { Tooltip } from 'react-tippy'

import { searchDealers } from '../../api/searchDealers'
import DealershipRegisteredModal from '../DealershipRegisteredModal'

interface Dealer {
  _id: {
    onboarded: boolean
    va_seller_address: string
    va_seller_city: string
    va_seller_name: string
    va_seller_country: string
    va_seller_state: string
    va_seller_domains: string
    va_seller_zip: string
    va_seller_phones: string
    va_seller_websites: string
    va_seller_makes: string
    va_seller_type: string
  }
}

interface DealershipSearchProps {
  formFieldErrors: any
  setFormItemValue: (name: string, value: string) => void
  openSupportTab: () => void
  openModalOverlay: () => void
  additionalDomains: Array<string>
  setAdditionalDomains: (domains: Array<string>) => void
  country: string
  resetValidation: any
  session_token: string
  agent_id: string
}

const DealershipSearch = ({
  setFormItemValue,
  formFieldErrors,
  openSupportTab,
  openModalOverlay,
  additionalDomains,
  setAdditionalDomains,
  country,
  resetValidation,
  session_token,
  agent_id,
}: DealershipSearchProps) => {
  const [agentSearchValue, setAgentSearchValue] = useState('')
  const [agentSearchResults, setAgentSearchResults] = useState<
    Array<Dealer> | false
  >(false)
  const [dealershipError, setDealershipError] = useState(false)
  const [selectedDealer, setSelectedDealer] = useState<Dealer | false>(false)
  const [showDealershipRegisteredModal, setShowDealershipRegisteredModal] =
    useState(false)

  const openSupportOverlay = () => {
    openModalOverlay()
    setShowDealershipRegisteredModal(false)
  }

  const dropDownRef = useRef<any>(null)
  /**
   * Click outside listener
   */
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setAgentSearchResults(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropDownRef])
  const handleSearchDealers = (e: any) => {
    setAgentSearchValue(e.target.value)
  }

  useEffect(() => {
    if (agentSearchValue !== '') {
      const searchValue = agentSearchValue
        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
        .split('/')[0]
      const timer = setTimeout(() => {
        searchDealers(searchValue, country, session_token, agent_id)
          .then((res) => {
            setAgentSearchResults(() => [...res.data])
          })
          .catch((e: Error | AxiosError) => {
            setDealershipError(true)
            console.log(e)
          })
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [agentSearchValue])

  const handleOptionClick = (option: any) => {
    setAgentSearchResults(false)
    setAgentSearchValue(option?._id?.va_seller_websites)
    if (option?._id?.onboarded) {
      setShowDealershipRegisteredModal(true)
      return
    }
    setSelectedDealer(option)
    setFormItemValue('dealership_name', option?._id?.va_seller_name)
    setFormItemValue('dealership_website', option?._id?.va_seller_websites)
    setFormItemValue('dealership_phone', option?._id?.va_seller_phones)
    setFormItemValue('dealership_providers', option?._id?.va_seller_makes)
    setFormItemValue('dealership_domain', option?._id?.va_seller_domains)
  }

  const addAdditionalDomainField = () => {
    if (additionalDomains.length > 1) {
      return
    }
    setAdditionalDomains([...additionalDomains, ''])
  }
  const handleChangeAdditionalDomain = (e: any) => {
    const value = e.target.value
    const index = e.target.name
    const domains = [...additionalDomains]
    domains[index] = value
    setAdditionalDomains(domains)
  }

  //Reset the selected dealer if the selected country changes.
  const resetDealer = () => {
    setSelectedDealer(false)
    setAgentSearchValue('')
    setFormItemValue('dealership_name', '')
    setFormItemValue('dealership_website', '')
    setFormItemValue('dealership_phone', '')
    setFormItemValue('dealership_providers', '')
    setFormItemValue('dealership_domain', '')
    resetValidation()
  }

  useEffect(() => {
    if (country === '') {
      return
    }
    resetDealer()
  }, [country])
  return (
    <div className="mb-6">
      <label
        htmlFor="dealership-web-address"
        className="mb-[6px] flex text-left text-xs "
      >
        Dealership Web Address <span className="text-red-700	">*</span>
        <Tooltip
          title="Use the search bar below to find the dealership by typing their website address. Select the dealership from the list of suggested results"
          position="top"
          arrow={true}
        >
          <AiOutlineQuestionCircle className="hover:cursor-pointer" />
        </Tooltip>
      </label>
      <div className="relative">
        <input
          disabled={country == ''}
          id="dealership_website"
          type="text"
          placeholder="www.example.com"
          className={`${
            formFieldErrors.dealership_website
              ? 'border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error'
              : 'bg-transparent outline-none'
          }
              w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:cursor-not-allowed disabled:bg-zinc-100		
              `}
          value={agentSearchValue.split(',')[0]}
          onChange={(e) => handleSearchDealers(e)}
        />
        <span className="pointer-events-none absolute">
          <FiSearch className="-ml-10 mt-3 h-5 w-5" />
        </span>
        {agentSearchResults && agentSearchValue !== '' && (
          <div
            ref={dropDownRef}
            className={`dropdown-content absolute z-10 my-2 w-full rounded border-2 bg-white p-5 transition duration-300 ease-in-out`}
          >
            {!agentSearchResults.length ? (
              <div className={`block p-2 text-paragraph`}>No results found</div>
            ) : (
              agentSearchResults?.map((option) => (
                <div
                  key={
                    option?._id?.va_seller_phones +
                    option?._id?.va_seller_country
                  }
                  className={`block cursor-pointer p-2 text-paragraph hover:bg-sidebar-bk`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option?._id?.va_seller_websites?.split(',')[0]}
                </div>
              ))
            )}
          </div>
        )}
      </div>

      {selectedDealer ? (
        <div>
          <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="grid w-full gap-4 xs:grid-cols-1 sm:grid-cols-2">
            <div className="mb-6">
              <label className="mb-[6px] block text-left text-xs ">
                Dealer Name
              </label>
              <p>{selectedDealer?._id?.va_seller_name}</p>
            </div>
            <div className="mb-6">
              <label className="mb-[6px] block text-left text-xs ">
                Website
              </label>
              <p>{selectedDealer?._id?.va_seller_websites}</p>
              {additionalDomains.length < 2 && (
                <div
                  className="mr-[54px] mt-3.5 flex cursor-pointer flex-row items-center text-base text-primary"
                  onClick={() => addAdditionalDomainField()}
                >
                  <AiFillPlusCircle />
                  <span className="ml-1">
                    Add additional inventory domain
                  </span>{' '}
                  <Tooltip
                    title="If this dealership splits it's inventory between websites, you can add up to two (2) additional domains here. One example is a Chevrolet, GMC, and Cadillac dealer that lists their inventory separately, a website visitor cannot view the Cadillac inventory on the Chevrolet website or vice versa."
                    position="top"
                    arrow={true}
                  >
                    <AiOutlineQuestionCircle className="hover:cursor-pointer" />
                  </Tooltip>
                </div>
              )}

              {additionalDomains.map((domain: any, index) => {
                return (
                  <div className="mb-6 mt-6" key={index}>
                    <label
                      htmlFor="agent-id-number"
                      className="mb-[6px] flex text-left text-xs "
                    >
                      Additional Domain
                    </label>
                    <input
                      name={index.toString()}
                      type="text"
                      placeholder="Domain"
                      className={
                        'w-full rounded  bg-transparent px-5 py-2 outline-none outline-none transition disabled:cursor-default'
                      }
                      value={domain}
                      onChange={handleChangeAdditionalDomain}
                    />
                  </div>
                )
              })}
            </div>
            <div className="mb-6">
              <label className="mb-[6px] block text-left text-xs ">
                Phone Number
              </label>
              <p>{selectedDealer?._id?.va_seller_phones}</p>
            </div>
            <div className="mb-6">
              <label className="mb-[6px] block text-left text-xs ">
                OEM Brands
              </label>
              <p>{selectedDealer?._id?.va_seller_makes}</p>
            </div>
            <div className="mb-6">
              <label className="mb-[6px] block text-left text-xs ">
                Address
              </label>
              <p>{`${selectedDealer?._id?.va_seller_address}, ${selectedDealer?._id?.va_seller_city}, ${selectedDealer?._id?.va_seller_state}, ${selectedDealer?._id?.va_seller_zip}`}</p>
            </div>
          </div>
          <div className="grid w-full grid-cols-1">
            <p className="mt-3.5">
              <span className="mr-[25px] inline-flex text-base	text-warning-bk">
                <AiOutlineWarning height={'16px'} className="warning" />
              </span>
              <span className="text-base text-warning">
                If you spot any errors in the information above,{' '}
              </span>
              <span
                className="cursor-pointer text-base text-primary hover:underline"
                onClick={openSupportTab}
              >
                please use contact support.
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div
          className={`signin-dialog mb-[21px] mt-[65px] h-96 w-full rounded-lg ${
            dealershipError ? 'bg-error-bk' : 'bg-primary-bk'
          } shadow-none xs:pt-16 md:px-32 md:py-32 md:pt-32 lg:px-16 lg:py-16 lg:pt-20	`}
        >
          {dealershipError ? (
            <p className="text-center text-error">
              Website Not Found
              <br />
              The web address you’ve entered does not seem to match any dealer
              entries in our system. <br />
              Please ensure the web address is entered correctly. If the problem
              persists, please use the chat button in the bottom right corner to
              contact support.
            </p>
          ) : (
            <p className="text-center text-paragraph">
              Add a dealership by entering the website address of the dealership
              you would like to onboard in the “Dealership Website URL” field
              above.
            </p>
          )}
        </div>
      )}
      <hr className="mb-[52px] h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
      {showDealershipRegisteredModal && (
        <DealershipRegisteredModal
          setModalOpen={setShowDealershipRegisteredModal}
          openSupportTab={openSupportOverlay}
        />
      )}
    </div>
  )
}

export default DealershipSearch
