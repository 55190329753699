import Modal from '../Modal'

interface ErrorModalProps {
  setModalOpen: (open: boolean) => void
  openSupportTab: () => void
  message: string
}
const ErrorModal = ({
  setModalOpen,
  openSupportTab,
  message,
}: ErrorModalProps) => {
  return (
    <Modal
      isOpen={true}
      onClose={() => setModalOpen(false)}
      children={
        <div className="flex h-[450px] flex-col	">
          <h2 className="mb-16	text-3xl text-paragraph">Error</h2>
          <p className="text-paragraph">{message}</p>

          <div className="mt-auto">
            <div className="ml-auto flex w-6/12 justify-end">
              <button
                type="submit"
                className=" btn btn-secondary mr-[41px] rounded-lg px-8 py-4 text-sm"
                onClick={() => setModalOpen(false)}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
                onClick={openSupportTab}
              >
                Contact Support
              </button>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default ErrorModal
