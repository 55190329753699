import axios from 'axios'

import { devAPI } from './constants'

export const searchLenders = async (
  query: string,
  country: string,
  session_token: string,
  agent_id: string
) => {
  country = country.toUpperCase()
  const res = await axios.get(devAPI + '/search-lenders', {
    params: {
      country,
      query,
      session_token,
      agent_id,
    },
  })
  return res
}
