import type { AxiosError } from 'axios'
import { useState } from 'react'

import { sendAgentLoginEmail } from '../../api/agentLogin'
import Modal from '../Modal'

const LoginForm = () => {
  const [email, setEmail] = useState('')
  const [agentID, setAgentID] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState()
  const [isErrorLogin, setIsErrorLogin] = useState(false)

  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value)
  }
  const handleChangeId = (e: any) => {
    setAgentID(e.target.value)
  }

  const sendEmailQuery = () => {
    const jsonQuery = btoa(JSON.stringify({ email: email, agentId: agentID }))
    setIsLoading(true)
    sendAgentLoginEmail(jsonQuery, 0)
      .then((res) => {
        setResponse(res.data)
        setIsLoading(false)
      })
      .catch((e: Error | AxiosError) => {
        setIsErrorLogin(true)
        setIsLoading(false)
      })
  }

  return response ? (
    <div className="grid h-full w-full xs:w-11/12 xs:grid-cols-3 xs:gap-4">
      <div className="xs:col-span-3 xl:col-span-2">
        <h1 className="text-5xl">Agent Login Email Sent</h1>
        <div className="mb-[27px] mt-[27px] text-justify">
          We've sent a new agent login email to the email address we have on
          file. Make sure to click the link in the email within 12 hours. Any
          previous login emails will no longer work.
          <br />
          <br />
          If you have any issues, please{' '}
          <a
            href="mailto:support@carbeeza.com"
            className="text-primary hover:underline"
          >
            contact support
          </a>
          .
        </div>
      </div>
    </div>
  ) : (
    <div className="grid h-full w-full xs:w-11/12 xs:grid-cols-3 xs:gap-4">
      <div className="xs:col-span-3 xl:col-span-2">
        <h1 className="text-5xl">Carbeeza Account Registration</h1>
        <div className="mb-[27px] mt-[27px] text-justify">
          To sign in, enter your email address and agent ID. We will send you an
          email with a link to access the registration form.
        </div>
        <div className="mb-[27px]">
          <label
            htmlFor="agent-id-number"
            className="mb-[6px] block text-left text-xs "
          >
            Email Address
          </label>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="email@email.com"
            className={`w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
                  `}
            value={email}
            onChange={handleChangeEmail}
          />
        </div>
        <div className="mb-[27px]">
          <label
            htmlFor="agent-id-number"
            className="mb-[6px] flex text-left text-xs "
          >
            Agent ID Number{' '}
            {/* <Tooltip
              title="If you are an agent, please enter your unique Agent ID number to claim this dealership as your client. Please check your entry is correct, any errors may result in lost or delayed payment. If you have misplaced your ID number, please contact support."
              position="top"
              arrow={true}
            >
              <AiOutlineQuestionCircle className="hover:cursor-pointer" />
            </Tooltip> */}
          </label>
          <input
            id="agent_id"
            name="agent_id"
            type="number"
            placeholder="123456"
            className={
              'w-full rounded  bg-transparent px-5 py-2 outline-none outline-none transition [appearance:textfield] disabled:cursor-default [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
            }
            value={agentID}
            onChange={handleChangeId}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-', '.'].includes(evt.key) &&
              evt.preventDefault()
            }
          />
        </div>
        <div>
          <button
            type="submit"
            className="btn-primary flex w-full justify-center rounded-lg py-3"
            disabled={isLoading}
            onClick={sendEmailQuery}
          >
            Continue
            {isLoading && (
              <svg
                aria-hidden="true"
                className="text-gray-200 dark:text-gray-600 ml-[5px] mr-2 mt-[5px] inline h-6 w-6 animate-spin fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
          </button>
        </div>
        <div className="mb-[27px] mt-[27px] text-justify">
          If you would like to apply to become a Carbeeza agent, please visit{' '}
          <a
            href="https://corp.carbeeza.com/agents"
            target="_blank"
            className="text-primary"
          >
            corp.carbeeza.com/agents
          </a>
          .
        </div>
      </div>
      {isErrorLogin ? (
        <Modal
          isOpen={isErrorLogin}
          onClose={() => setIsErrorLogin(false)}
          children={
            <div className="flex h-[350px] flex-col	">
              <h2 className="mb-[40px] text-[32px]	font-medium text-[paragraph]">
                Hmm... There seems to be a problem.
              </h2>
              <p className="text-[#3B2F66} text-[20px]">
                Please try again later. If the problem persists, contact
                support.
              </p>
              <div className="mt-auto">
                <div className="ml-auto flex w-6/12 justify-end">
                  <button
                    type="submit"
                    className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
                    onClick={() => setIsErrorLogin(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          }
        ></Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default LoginForm
