import Modal from '../Modal'

interface DealershipRegisteredModalProps {
  setModalOpen: (open: boolean) => void
  openSupportTab: () => void
}
const DealershipRegisteredModal = ({
  setModalOpen,
  openSupportTab,
}: DealershipRegisteredModalProps) => {
  return (
    <Modal
      isOpen={true}
      onClose={() => setModalOpen(false)}
      children={
        <div className="flex h-[450px] flex-col	">
          <h2 className="mb-16	text-3xl text-paragraph">
            Dealership Registered
          </h2>
          <p className="text-paragraph">
            It looks like this dealership has already been registered with
            Carbeeza.
            <br />
            If you believe this is an error, please use the chat button in the
            bottom right corner to contact support.
          </p>

          <div className="mt-auto">
            <div className="ml-auto flex w-6/12 justify-end">
              <button
                type="submit"
                className=" btn btn-secondary mr-[41px] rounded-lg px-8 py-4 text-sm"
                onClick={() => setModalOpen(false)}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary rounded-lg px-8 py-4 text-sm	"
                onClick={openSupportTab}
              >
                Chat With Support
              </button>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default DealershipRegisteredModal
