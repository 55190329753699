import axios from 'axios'

import { devAPI } from './constants'

export const sendAgentLoginEmail = async (query: string, api: number) => {
  const res = await axios.get(devAPI + '/send-agent-login-email', {
    params: {
      data: query,
      api,
    },
  })
  return res
}

export const agentLogin = async (query: string) => {
  const res = await axios.get(devAPI + '/agent-login', {
    params: {
      data: query,
    },
  })
  return res
}
