interface LoginErrorScreenProps {
  openSupportTab: () => void
}
const LoginErrorScreen = ({ openSupportTab }: LoginErrorScreenProps) => {
  const refreshPage = () => {
    location.reload()
  }
  return (
    <div className="grid h-full w-full xs:w-11/12 xs:grid-cols-3 xs:gap-4">
      <div className="xs:col-span-3 xl:col-span-2">
        <div className="mb-[27px] mt-[27px] text-justify">
          We were unable to log you into the Onboarding Portal. Please{' '}
          <span
            className="cursor-pointer text-base text-primary"
            onClick={() => refreshPage()}
          >
            try again
          </span>{' '}
          or{' '}
          <span
            className="cursor-pointer text-base text-primary"
            onClick={openSupportTab}
          >
            contact support
          </span>
          .
        </div>
      </div>
    </div>
  )
}

export default LoginErrorScreen
