import axios from 'axios'

import { devAPI } from './constants'

export const newDealerGroup = async (params: any) => {
  const res = await axios.post(devAPI + '/new-dealer-group', {
    ...params,
  })
  return res
}
