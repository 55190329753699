import type { ReactNode } from 'react'
import { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setIsOverlayVisible(true)
      document.addEventListener('click', handleOutsideClick)
    } else {
      setIsOverlayVisible(false)
      document.removeEventListener('click', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  const handleOutsideClick = (event: MouseEvent) => {
    event.stopPropagation()
    const modal = document.querySelector('.modal-wrapper.block .modal')
    if (modal && !modal.contains(event.target as Node) && !isOpen) {
      onClose()
    }
  }

  return (
    <>
      {isOverlayVisible && (
        <div className="fixed inset-0 z-40 bg-[#120E23B0] opacity-75 transition-opacity"></div>
      )}

      <div
        className={`modal-wrapper fixed inset-0 z-50 overflow-y-auto ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <div className="flex min-h-screen items-center justify-center pb-[15%]">
          <div className="modal transform overflow-hidden rounded-3xl bg-white shadow-xl transition-all sm:max-w-[50rem] md:w-full">
            <div className="flex justify-end pr-6 pt-5">
              <button
                className="text-paragraph hover:text-paragraph/60"
                onClick={onClose}
              >
                <MdClose className="h-6 w-6" />
              </button>
            </div>
            <div className="modal-body mt-2 px-4 pb-12 pt-5 sm:px-12">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
