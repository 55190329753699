import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { Tooltip } from "react-tippy";

interface PlanSelectProps {
	formFieldErrors: any;
	setFormItemValue: (name: string, value: string) => void;
	plan: string;
	pricingOptions: any[];
	country: string;
}

const PlanSelect = ({
	setFormItemValue,
	formFieldErrors,
	plan,
	pricingOptions,
	country
}: PlanSelectProps) => {
	const [searchValue, setSearchValue] = useState("");
	const [showOptions, setShowOptions] = useState(false);
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [showPriceOptions, setShowPriceOptions] = useState(false);
	const [priceValue, setPriceValue] = useState("");
	const dropDownRef = useRef<any>(null);
	const priceDropDownRef = useRef<any>(null);

	/**
	 * Click outside plans listener
	 */
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				dropDownRef.current &&
				!dropDownRef.current.contains(event.target)
			) {
				setShowOptions(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropDownRef]);

	/**
	 * Click outside price listener
	 */
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				priceDropDownRef.current &&
				!priceDropDownRef.current.contains(event.target)
			) {
				setShowPriceOptions(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [priceDropDownRef]);

	useEffect(() => {
		setSearchValue(plan);
		setPriceValue("");
		setFormItemValue("price", "");
		setFilteredOptions([]);
	}, [plan]);

	const uniquePlans = [...new Set(pricingOptions.map((data) => data.plan))];

	const handleOptionClick = (option: any) => {
		setFormItemValue("plan", option);
		if (["custom", "enterprise"].includes(option.toLowerCase())) {
			// need to delay this slightly so the other state updates don't clobber it
			setTimeout(() => {
				handlePriceOptionClick("0");
			}, 1);
		}
		setSearchValue(option);
		setShowOptions(false);
	};

	useEffect(() => {
		setPriceValue("");
		setFormItemValue("price", "");
		setFilteredOptions([]);
		filterPlans();
	}, [country, plan]);

	const filterPlans = () => {
		const filteredArray: Array<string> = _.filter(pricingOptions, {
			country: country.toUpperCase(),
			plan: plan
		});
		setFilteredOptions(filteredArray);
	};
	const handlePriceOptionClick = (option: any) => {
		setShowPriceOptions(false);
		setPriceValue(option);
		setFormItemValue("price", option);
	};

	return (
		<>
			<div className="mb-[6px]">
				<label
					htmlFor="plan-label"
					className="mb-[6px] flex text-left text-xs "
				>
					Ultralead Plan <span className="text-red-700">*</span>
					<Tooltip
						title="You can upgrade, downgrade, or cancel your plan at any time. No fees, and no fine print."
						position="top"
						arrow={true}
					>
						<AiOutlineQuestionCircle className="hover:cursor-pointer" />
					</Tooltip>
				</label>
				<div className="relative">
					<input
						id="plan"
						name="plan"
						type="select"
						placeholder="Select Plan"
						className={`${
							formFieldErrors.dealership_country ||
							formFieldErrors.plan
								? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
								: "bg-transparent outline-none"
						}
              w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default
              `}
						value={searchValue}
						onClick={() => setShowOptions(true)}
						readOnly
					/>
					<span className="pointer-events-none absolute">
						<FiChevronDown
							size={24}
							className="-ml-10 mt-3 h-5 w-5"
						/>
					</span>
					{showOptions && (
						<div
							ref={dropDownRef}
							className={`dropdown-content absolute z-10 my-2 w-full rounded border-2 bg-white p-5 transition duration-300 ease-in-out`}
						>
							{uniquePlans?.map((planOption, idx) => {
								return (
									<div
										key={planOption + idx}
										className={`block cursor-pointer p-2 text-paragraph hover:bg-sidebar-bk`}
										onClick={() =>
											handleOptionClick(planOption)
										}
									>
										{planOption}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			<div className="mb-[6px]">
				<label
					htmlFor="plan-label"
					className="mb-[6px] flex text-left text-xs "
				>
					Pricing <span className="text-red-700	">*</span>
				</label>
				<div className="relative">
					<input
						id="price"
						name="price"
						type="select"
						placeholder="Select Plan"
						disabled={
							country === "" ||
							["custom", "enterprise", ""].includes(
								plan.toLowerCase()
							)
						}
						className={`${
							formFieldErrors.dealership_country ||
							formFieldErrors.price
								? "border-2 border-error bg-error-bk text-error placeholder-red-700 focus:border-error active:border-error"
								: "bg-transparent outline-none"
						}
              w-full rounded  px-5 py-2 outline-none transition disabled:cursor-default disabled:cursor-not-allowed disabled:bg-zinc-100
              `}
						value={priceValue}
						onClick={() => setShowPriceOptions(true)}
						readOnly
					/>
					<span className="pointer-events-none absolute">
						<FiChevronDown
							size={24}
							className="-ml-10 mt-3 h-5 w-5"
						/>
					</span>
					{showPriceOptions && (
						<div
							ref={priceDropDownRef}
							className={`dropdown-content absolute z-10 my-2 w-full rounded border-2 bg-white p-5 transition duration-300 ease-in-out`}
						>
							{filteredOptions?.map((option, idx) => {
								return (
									<div
										key={option + idx}
										className={`block cursor-pointer p-2 text-paragraph hover:bg-sidebar-bk`}
										onClick={() =>
											handlePriceOptionClick(option.price)
										}
									>
										{option.price}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PlanSelect;
